/* large screens */
.header {
  position: fixed;
  top: 0;
  z-index: 110;
  width: 100%;
  height: 40px;
  transition: all 0.3s ease-in-out;
  background-color: transparent;
  padding: 13px 0;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.menu-container-lg {
  position: relative;
  display: flex;
  transition: all 0.3s ease-in-out;
  justify-content: center;
  align-items: center;
}
.menu-item {
  position: relative;
  margin: 5px 20px;
  padding: 5px 0;
  text-decoration: none;
  color: rgb(255, 243, 243);
  font-size: 0.9rem;
  font-weight: 600;
  letter-spacing: 0.9px;
  text-align: center;
  cursor: pointer;
}
.menu-item::before {
  content: "";
  position: absolute;
  top: 100%;
  width: 100%;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
  height: 2px;
  border-radius: 10px;
  background-color: #ffbc13;
}
.menu-item:hover::before {
  transform: scaleX(1);
}
.menu-item-active::before {
  transform: scaleX(1) !important;
}
.header-title {
  position: absolute;
  left: 0;
  /* top: 50%; */
  margin-left: 15px;
  font-weight: 600;
  /* transform: translateY(-50%); */
  display: none;
  padding: 5px 0;
  letter-spacing: 1px;
  z-index: 100;
  color: black;
  /* border-bottom: 3px solid rgb(48, 150, 179); */
}
.header-title::before {
  content: "";
  position: absolute;
  top: 100%;
  width: 50%;
  border-radius: 10px;
  height: 3.5px;
  background-color: #ffbc13;
  left: 0;
}
.header-scroll {
  background-color: white;
}
.header-scroll .header {
  display: flex;
  justify-content: flex-start;
}
.header-scroll > .menu-container-lg {
  display: flex;
  transition: all 0.3s ease-in-out;
  justify-content: flex-end;
  margin-right: 50px;
}
.header-scroll > .menu-container-lg > .menu-item {
  color: black;
}
.header-scroll > .menu-container-lg > .menu-item::before {
  background-color: #ffbc13;
}
.header-scroll .header-title {
  display: block !important;
}
.menu-container-sm {
  text-align: right;
  margin: 0;
  flex-direction: row-reverse;
  display: none;
}
.menu-icon {
  display: none;
  color: white;
  font-size: 2.3rem;
}
/* mobile screens */
@media screen and (max-width: 786px) {
  .header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .menu-container-lg {
    display: none;
  }
  .menu-container-sm {
    text-align: right;
    margin: 0;
    flex-direction: row-reverse;
    display: block;
  }
  .header-title {
    position: relative;
  }
  .menu-sm {
    margin-top: 25px;
  }
  .menu-icon {
    color: white;
    display: block;
    font-size: 2.1rem !important;
  }
  .menu-title-container {
    margin-top: 25px;
  }
  .menu-title {
    position: relative;
    font-size: 1.2rem;
    font-weight: 600;
    color: black;
    padding: 10px 0px 5px;
    margin: 20px;
  }
  .menu-title::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0%;
    width: 30%;
    height: 4px;
    border-radius: 10px;
    background-color: #ffbc13;
  }
  .menu-item {
    display: block;
    padding: 10px 5px;
    font-size: 1.1rem;
    color: black;
    text-align: left;
  }
  .header-scroll {
    justify-content: space-between;
  }
  .header-scroll > .menu-container-lg {
    display: none;
  }
  .header-scroll .menu-icon {
    color: black !important;
    font-size: 1.6rem !important;
  }
}
